<template>
  <main class="solana-page">
    <h1 class="solana-title">
      {{ $t('solana.all_pools') }}
    </h1>

    <div class="all-pools">

      <div class="all-pools-cards">
        <SolanaPoolCard
          v-for="(card, i) of cards"
          :key="i"
          :card="card"
          light
          url="/cardano/pool"
        />
      </div>

    </div>
  </main>
</template>

<script>
import SolanaPoolCard from '@/components/Solana/SolanaPoolCard';

export default {
  name: 'Cardano',
  components: { SolanaPoolCard },
  data() {
    return {
      cards: [
        {
          image: '4.png',
          name: 'SHT/ETH',
          sub_title: '',
          liquidity: '$ 157,003.32',
          average: '42%',
          link: '',
        },
        {
          image: '5.png',
          name: 'SHT',
          sub_title: '',
          liquidity: '$ 157,003.32',
          average: '42%',
          link: '',
        },
        {
          image: '6.png',
          name: 'SHT',
          sub_title: 'Borrowable',
          liquidity: '$ 157,003.32',
          average: '42%',
          link: '',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">

</style>
